import { useMemo } from 'react';
import { PlayArrowOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { CrudFilters, getDefaultFilter, useInvalidate } from '@refinedev/core';
import { List, useDataGrid, DateField, EditButton, DeleteButton } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

import { buildPackage } from './components/build_package';
import { Status } from './components/constants';

const FlexPackagesList = () => {
  const invalidate = useInvalidate();

  const columns = useMemo<GridColumns>(() => {
    const invalidateListQuery = () => {
      invalidate({
        resource: 'flex_packages',
        invalidates: ['list']
      });
    };

    return [
      {
        field: 'name',
        headerName: 'Flex Package Name',
        width: 200,
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.name}>
              <Typography variant="body1" noWrap>
                {params.row.name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'package_type',
        headerName: 'Package Type',
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.package_type_name}>
              <Typography variant="body1" noWrap>
                {params.row.package_type_name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'version',
        headerName: 'Version',
        flex: 1,
        renderCell: params => {
          return (
            <Tooltip title={params.row.version}>
              <Typography variant="body1" noWrap>
                {params.row.version}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'executor_version',
        headerName: 'Executor version',
        flex: 1,
        renderCell: params => {
          return (
            <Tooltip title={params.row.executor_version}>
              <Typography variant="body1" noWrap>
                {params.row.executor_version}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'project_template',
        headerName: 'Project Template',
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.project_template}>
              <Typography variant="body1" noWrap>
                {params.row.project_template}
              </Typography>
            </Tooltip>
          );
        },
        sortable: false
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: params => {
          return (
            <Tooltip title={params.row.status}>
              <Typography variant="body1" noWrap>
                {params.row.status}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'created',
        headerName: 'Created At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'modified',
        headerName: 'Updated At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <Button
                sx={{ minWidth: 0 }}
                startIcon={<PlayArrowOutlined sx={{ selfAlign: 'center' }} />}
                title="Recreate Package"
                disabled={params.row.status === Status.IN_PROGRESS}
                onClick={() => {
                  buildPackage({ id: params.row.id, onFinish: invalidateListQuery });
                }}
              />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        }
      }
    ];
  }, [invalidate]);

  const { dataGridProps, filters, search } = useDataGrid({
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { search } = params;

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'search',
          operator: 'eq',
          value: ''
        }
      ]
    }
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: getDefaultFilter('search', filters, 'eq') || ''
    }
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <Card>
          <CardHeader title="Filters" />
          <CardContent>
            <Box
              component="form"
              autoComplete="off"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit(search)}
            >
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value || ''}
                    onChange={onChange}
                    label="Flex Package"
                    placeholder="Search by name"
                    margin="normal"
                    fullWidth
                    autoFocus
                  />
                )}
              />

              <br />
              <Button type="submit" variant="contained">
                Apply
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={9}>
        <List canCreate>
          <DataGrid {...dataGridProps} filterModel={undefined} autoHeight columns={columns} disableColumnMenu />
        </List>
      </Grid>
    </Grid>
  );
};

export default FlexPackagesList;
